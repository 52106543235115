import { render, staticRenderFns } from "./UsersOverview.vue?vue&type=template&id=48a0732f"
import script from "./UsersOverview.vue?vue&type=script&lang=ts&setup=true"
export * from "./UsersOverview.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports